import { observeElementVisibility } from '../../lib/element-visibility.js';

// TODO: this module needs to be refactored. this module should not have specific knowledge of the
// various DOM structure used by banners. instead, banners should be tagged, e.g. with a data
// attribute such as "data-is-promo-banner", and this should query for that attribute.

/**
 * Fired when a user clicks on a promotional banner.
 *
 * This event is primarily intended for analytics.
 */
type PromoBannerClickedEvent = CustomEvent<{
  /**
   * The coupon code associated with the banner.
   */
  coupon_code?: string;

  /**
   * An identifier for the promotion that is associated with the banner.
   */
  promotion_id?: string;

  /**
   * A human-readable name for the promotion associated with the banner.
   */
  promotion_name?: string;
}>;

/**
 * Fired when a user views a promotional banner.
 *
 * This event is intended primarily for analytics.
 */
type PromoBannerViewedEvent = CustomEvent<{
  /**
   * The coupon code associated with the banner.
   */
  coupon_code: string;
}>;

declare global {
  interface WindowEventMap {
    'promo-banner-clicked': PromoBannerClickedEvent;
    'promo-banner-viewed': PromoBannerViewedEvent;
  }
}

/**
 * Capture interaction events for promotions rendered as ad-like banners on various pages in the
 * website. When a promotion is sufficiently "viewed", fire a promotion viewed event.
 */
function onDOMContentLoaded(_event: Event) {
  const banners = findBanners();
  observeBannerClicks(banners);
  observeBannerViews(banners);
}

/**
 * Attaches observation to the given banners that eventually fires an event once a banner is deemed
 * to have been viewed.
 *
 * @todo this should be using an attribute of the banner element to determine the associated coupon
 * code, not pulling it out of a nested anchor
 *
 * @todo couponcode may contain comma separated list of discount codes, this needs to support
 * multiple discount codes, right now promo-banner-viewed property "coupon_code" is misleading
 */
function observeBannerViews(banners: HTMLElement[]) {
  const callback = (banner: HTMLElement) => {
    let anchor;
    if (banner.matches('section')) {
      anchor = banner.querySelector<HTMLAnchorElement>('a.banner[href]');
    }

    if (!anchor) {
      return;
    }

    const url = parseURL(anchor?.href);
    const couponCode = url?.searchParams.get('couponcode');
    if (couponCode) {
      type Detail = PromoBannerViewedEvent['detail'];
      const event = new CustomEvent<Detail>('promo-banner-viewed', {
        cancelable: false,
        detail: {
          coupon_code: couponCode
        }
      });
      dispatchEvent(event);
    }
  };

  for (const banner of banners) {
    observeElementVisibility(banner, callback.bind(null, banner), { threshold: 0.9 });
  }
}

function observeBannerClicks(banners: HTMLElement[]) {
  for (const banner of banners) {
    if (banner.matches('section')) {
      const anchor = banner.querySelector<HTMLAnchorElement>('a.banner[href]');
      anchor?.addEventListener('click', onBannerClick);
    }
  }
}

/**
 * Handle the click on a promotional banner. Emit a banner clicked event that integrations can use
 * for marketing.
 */
function onBannerClick(event: MouseEvent) {
  const anchor = <HTMLAnchorElement>event.currentTarget;
  const url = parseURL(anchor.href);
  const couponCode = url?.searchParams.get('couponcode');
  if (!couponCode) {
    return;
  }

  type Detail = PromoBannerClickedEvent['detail'];
  const clickedEvent = new CustomEvent<Detail>('promo-banner-clicked', {
    cancelable: false,
    detail: {
      coupon_code: couponCode,
      promotion_id: anchor.dataset.promotionId,
      promotion_name: anchor.dataset.promotionName
    }
  });
  dispatchEvent(clickedEvent);
}

/**
 * Look for promotional banners like those displayed on the homepage or the sale page.
 */
function findBanners() {
  const banners: HTMLElement[] = [];

  // Homepage full-width/split banners with coupon code
  const type1BannerAnchors = document.querySelectorAll<HTMLAnchorElement>(
    'section > a.banner[href]');
  for (const anchor of type1BannerAnchors) {
    if (anchor.href) {
      const url = parseURL(anchor.href);
      const couponCode = url?.searchParams.get('couponcode');
      if (couponCode && anchor.parentElement?.tagName === 'SECTION') {
        banners.push(anchor.parentElement);
      }
    }
  }

  return banners;
}

function parseURL(value: unknown) {
  if (typeof value === 'string' && value.length > 0) {
    try {
      return new URL(value);
    } catch (error) {
    }
  }
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}

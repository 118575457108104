/**
 * Observes an element's visibility and fires a callback when the visible portion of the given
 * element is more than threshold.
 */
type ObserverOptions = {
  containerHeight?: number;
  threshold?: number;
};

export function observeElementVisibility(element: HTMLElement, callback: ()=> void,
  options: ObserverOptions = {}) {
  let timerId: ReturnType<typeof setTimeout>;

  const threshold = options.threshold || 1;
  const containerHeight = options.containerHeight || innerHeight;

  const scan = () => {
    const rect = element.getBoundingClientRect();

    // If the bottom of the element is inside the viewport, the visible height is simply the
    // difference between the top and bottom of the element. However, if the bottom of the element
    // is outside the viewport, the visible height is the difference between the top of the element
    // and the bottom of the viewport. This ensures that the visible height is always accurate, even
    // if the observable element's height is more than the visible viewport.

    let visibleHeight;
    if (rect.bottom > containerHeight) {
      visibleHeight = Math.max(containerHeight - rect.top, 0);
    } else {
      visibleHeight = Math.max(rect.bottom - rect.top, 0);
    }

    const visiblePercentage = Math.round(100 * (visibleHeight / rect.height)) / 100;

    if (visiblePercentage >= threshold) {
      removeEventListener('scroll', throttleScan);

      if (typeof callback === 'function') {
        callback();
      }
    }
  };

  const throttleScan = () => {
    if (timerId) {
      return;
    }

    scan();

    timerId = setTimeout(() => {
      clearTimeout(timerId);
      timerId = undefined;
    }, 100);
  };

  addEventListener('scroll', throttleScan, { passive: true });
  scan();
}
